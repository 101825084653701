import React from "react";

const PrivacyText = () => {
  return (
    <div>
      <p>
        <a
          href="https://am-bitious.de/datenschutz/"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://am-bitious.de/datenschutz/
        </a>
        <br />
        Datenschutzbeauftragter/DPO:{" "}
        <a href="mailto:datenschutz@toolcraft.de">datenschutz@toolcraft.de</a>
        <br />
      </p>
    </div>
  );
};

export default PrivacyText;
