import React from "react";

const ImprintText = () => {
  return (
    <div>
      <p>
        toolcraft AG
        <br />
        Handelsstraße 1<br />
        91166 Georgensgmünd
      </p>

      <p>
        Telefon: +49 9172 6956-0
        <br />
        Telefax: +49 9172 6956-560
      </p>

      <p>
        E-Mail: toolcraft@toolcraft.de
        <br />
        Internet: www.toolcraft.de
      </p>

      <p>UST.-IdNr.: DE 133528187</p>
    </div>
  );
};

export default ImprintText;
