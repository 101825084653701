import React from "react";

import {
  categories,
  contentpolicies,
  invitations,
  licenses,
  products,
  showrooms,
  tags,
  users,
  vishowcontentfiles,
  weblinks,
  auth,
  groups,
  languages,
  optinentries,
  permissions,
  translationentries,
  Icon,
  UserListLicensePill,
  downloadapp,
} from "@solidwhite/vis-base";

import * as ImprintAndLegal from "../modules/imprint";

export default {
  api: process.env.REACT_APP_API,
  routes: [],
  modules: [
    {
      name: "auth",
      module: auth,
      config: {
        identityPoolId: process.env.REACT_APP_IDENTITYPOOL_ID,
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USERPOOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID,
      },
      customErrorMessages: {
        noLoginPermission:
          "Your trial has ended. Please contact Solid White to get this resolved.",
      },
      postSignUpConfig: {
        enabled: true,
        downloadLink: process.env.REACT_APP_APPLICATION_DOWNLOAD_LINK,
        downloadLinkMac: process.env.REACT_APP_APPLICATION_DOWNLOAD_LINK_MAC,
      },
    },
    {
      name: "downloadapp",
      module: downloadapp,
      downloadLink: process.env.REACT_APP_APPLICATION_DOWNLOAD_LINK,
      headerImage: "Download_Banner.jpg",
      downloadText: "Download",
      headerText: "Download Virtual Innovation Area",
    },
    {
      name: "users",
      module: users,
      editable: false,
      expand: "UserGroups.Group,UserLicenses.License",
      schema: {
        Id: {
          visible: false,
        },
        Sub: {
          visible: false,
        },
        EmailVerified: {
          visible: false,
        },
        MiddleName: {
          visible: false,
        },
        PhoneNumber: {
          visible: false,
        },
        PhoneNumberVerified: {
          visible: false,
        },
        Nickname: {
          visible: false,
        },
        Birthdate: {
          visible: false,
        },
        Gender: {
          visible: false,
        },
        ZoneInfo: {
          visible: false,
        },
        Locale: {
          visible: false,
        },
        Type: {
          visible: false,
        },
        UserPermissions: {
          visible: false,
        },
        LastActiveAt: {
          visible: false,
        },
        UpdatedAt: {
          visible: false,
        },
        CreatedAt: {
          visible: false,
        },
        UserLicenses: {
          displayName: "License",
          type: "Join",
          entities: {
            local: "User",
            join: "UserLicenses",
            foreign: "License",
            filterString: (value) =>
              `Name != null and Name.Contains("${value}")`,
            getDisplayName: (entity) => `${entity.Name}`,
            displayName: "Name",
          },
          add: users.actions.addLicense,
          drop: users.actions.dropLicense,
        },
      },
      list: {
        schema: {
          UserLicenses: {
            component: UserListLicensePill,
          },
        },
      },
    },
    {
      name: "invitations",
      module: invitations,
      editable: false,
      expand: "Inviter,UserLicense.License",
      schema: {
        Id: {
          visible: false,
        },
        GivenName: {
          visible: false,
        },
        FamilyName: {
          visible: false,
        },
      },
      list: {
        schema: {
          Id: {
            visible: false,
          },
          CreatedAt: {
            visible: false,
          },
          UpdatedAt: {
            visible: false,
          },
          UserCanAlterEmail: {
            visible: false,
          },
          NotifyInviter: {
            visible: false,
          },
          UserLicense: {
            visible: false,
          },
        },
      },
    },
    {
      name: "licenses",
      module: licenses,
      expand: "LicenseGroups.Group", //UserLicenses.User',
      schema: {
        Id: {
          visible: false,
        },
        UserLicenses: {
          visible: false,
        },
        UpdatedAt: {
          visible: false,
        },
        CreatedAt: {
          visible: false,
        },
      },
    },
    {
      name: "groups",
      module: groups,
      expand: "GroupPermissions.Permission",
      schema: {
        Id: {
          visible: false,
        },
        CreatorType: {
          visible: false,
        },
        OwnerId: {
          visible: false,
        },
        CreatedAt: {
          visible: false,
        },
        UpdatedAt: {
          visible: false,
        },
      },
    },
    {
      name: "permissions",
      module: permissions,
      schema: {
        Id: {
          visible: false,
        },
        CreatorType: {
          visible: false,
        },
        UpdatedAt: {
          visible: false,
        },
        CreatedAt: {
          visible: false,
        },
      },
    },
    {
      name: "contentpolicies",
      module: contentpolicies,
      expand:
        "ContentPolicyShowrooms.Showroom,ContentPolicyCategories.Category",
      schema: {
        Id: {
          visible: false,
        },
        OwnerId: {
          visible: false,
        },
      },
    },
    {
      name: "categories",
      module: categories,
      expand: "ParentCategory",
      schema: {
        Id: {
          visible: false,
        },
        OwnerId: {
          visible: false,
        },
      },
    },
    {
      name: "showrooms",
      module: showrooms,
      //TODO: add expand for ShowroomLanguages update
      expand:
        "ShowroomCategories.Category,ShowroomProducts.Product,ShowroomTags.Tag,ShowroomWeblinks.Weblink",
      schema: {
        Id: {
          visible: false,
        },
        OwnerId: {
          visible: false,
        },
        //TODO: remove for ShowroomLanguages update
        ShowroomLanguages: {
          visible: false,
        },
      },
    },
    {
      name: "products",
      module: products,
      expand: "ProductTags.Tag,ProductWeblinks.Weblink",
      schema: {
        Id: {
          visible: false,
        },
        OwnerId: {
          visible: false,
        },
      },
    },
    {
      name: "tags",
      module: tags,
      schema: {
        Id: {
          visible: false,
        },
      },
    },
    {
      name: "weblinks",
      module: weblinks,
      schema: {
        Id: {
          visible: false,
        },
      },
    },
    {
      name: "vishowcontentfiles",
      module: vishowcontentfiles,
      expand:
        "ContentFileLanguages.Language,ProductViShowContentFiles.Product,ShowroomViShowContentFiles.Showroom",
      schema: {
        Id: {
          visible: false,
        },
        Hash: {
          visible: false,
        },
        OwnerId: {
          visible: false,
        },
      },
    },
    {
      name: "languages",
      module: languages,
      schema: {
        Id: {
          visible: false,
        },
        LanguageImage: {
          visible: false,
        },
      },
    },
    {
      name: "translationentries",
      module: translationentries,
      expand: "Language",
      schema: {
        Id: {
          visible: false,
        },
      },
    },
    {
      name: "optinentries",
      module: optinentries,
      schema: {
        TitleDisplayText: {
          visible: false,
        },
        TextDisplayText: {
          visible: false,
        },
      },
      list: {
        schema: {
          Id: {
            visible: false,
          },
          Text: {
            visible: false,
          },
        },
      },
    },
    {
      name: "imprint",
      module: ImprintAndLegal,
    },
  ],
};
