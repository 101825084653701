import React from "react";

import ReactDOM from "react-dom";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { ControlPanel } from "@solidwhite/vis-base";

import "./sass/_app.scss";

import config from "./js/config";

import packageJson from "../package.json";

import outdatedBrowserRework from "outdated-browser-rework";

// import ScrollToTop from '@solidwhite/swcp-base/lib/js/components/ScrollToTop';

console.log("vis-base: " + packageJson.dependencies["@solidwhite/vis-base"]);

outdatedBrowserRework({
  browserSupport: {
    Chrome: 57, // Includes Chrome for mobile devices
    Edge: 39,
    Safari: 10,
    "Mobile Safari": 10,
    Firefox: 50,
    Opera: false,
    Vivaldi: false,
    IE: 11,
  },
  requireChromeOnAndroid: false,
  isUnknownBrowserOK: false,
  messages: {
    en: {
      outOfDate:
        "Your browser version is not supported anymore.<br/>Please update your browser or use a different one.",
      unsupported:
        "Your browser is not supported.<br/>Please use a different one.",
    },
    de: {
      outOfDate:
        "Your browser version is not supported anymore.<br/>Please update your browser or use a different one.",
      unsupported:
        "Your browser is not supported.<br/>Please use a different one.",
    },
  },
});

ReactDOM.render(
  <BrowserRouter>
    {/* <ScrollToTop> */}

    <Switch>
      <Redirect path={"/"} exact={true} to={"/sign-in"} />

      <Route exact={false} component={() => <ControlPanel config={config} />} />

      <Route
        path={"/sign-up"}
        exact={true}
        render={(props) => <Redirect to={`/sign-up${props.location.search}`} />}
      />
    </Switch>

    {/* </ScrollToTop> */}
  </BrowserRouter>,

  document.getElementById("root")
);
